var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { height: "100%" } }, [
    _c(
      "div",
      { staticClass: "header-tab" },
      [
        _c(
          "div",
          { staticClass: "header-tab-title" },
          [
            _c(
              "el-tooltip",
              {
                attrs: {
                  effect: "dark",
                  content: _vm.tableTitle,
                  placement: "bottom",
                },
              },
              [
                _c("span", [
                  _vm._v(
                    " " + _vm._s(_vm.ellipsisText(_vm.tableTitle, 6)) + " "
                  ),
                ]),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "header-tab-button" },
          [
            _c(
              "el-tooltip",
              {
                staticClass: "item",
                attrs: { effect: "dark", content: "导入", placement: "top" },
              },
              [
                _c("span", { staticClass: "header-tab-button-icon" }, [
                  _c("i", { staticClass: "el-icon-folder-add" }),
                ]),
              ]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "small" },
                on: { click: _vm.handleAdd },
              },
              [_vm._v("发起")]
            ),
          ],
          1
        ),
        _c(
          "el-tabs",
          {
            attrs: { type: "card" },
            model: {
              value: _vm.activeName,
              callback: function ($$v) {
                _vm.activeName = $$v
              },
              expression: "activeName",
            },
          },
          [
            _c("el-tab-pane", { attrs: { name: "waitList" } }, [
              _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                _c("i", {
                  staticClass: "el-icon-date",
                  staticStyle: { margin: "2px" },
                }),
                _vm._v("待办视图"),
              ]),
            ]),
            _c("el-tab-pane", { attrs: { name: "doneList" } }, [
              _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                _c("i", {
                  staticClass: "el-icon-s-claim",
                  staticStyle: { margin: "2px" },
                }),
                _vm._v("已办视图"),
              ]),
            ]),
            _c("el-tab-pane", { attrs: { name: "possibilityList" } }, [
              _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                _c("i", {
                  staticClass: "el-icon-folder-checked",
                  staticStyle: { margin: "2px" },
                }),
                _vm._v("结办视图"),
              ]),
            ]),
            _c("el-tab-pane", { attrs: { name: "startedList" } }, [
              _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                _c("i", {
                  staticClass: "el-icon-position",
                  staticStyle: { margin: "2px" },
                }),
                _vm._v("发起视图"),
              ]),
            ]),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "body-tab" },
      [
        _c("FlowTable", {
          staticStyle: { height: "100%" },
          attrs: { form: _vm.form, tableType: _vm.activeName },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }