






























// class组件
import { Component, Vue, ProvideReactive } from 'vue-property-decorator';
// 组件
import MyFormDataManager from './components/form-data-manager/form-data-manager.vue';
import FlowDataManager from './components/form-data-manager/flow-data-manager.vue';
import RiverHeader from '@/views/layout/components/river-header/index.vue';
import LeftMenu from './left-menu.vue';
import FormViewer from '@/components/form-home-viewer/form-viewer.vue';
import MyHeader from './components/river-header/index.vue';
// 工具
// API
import AppApi from '@/api/application/AppApi';
@Component({
  name: 'usuallyApplication',
  components: { MyFormDataManager, FlowDataManager, LeftMenu, RiverHeader, FormViewer, MyHeader }
})
export default class FormDataList extends Vue {
  @ProvideReactive()
  formId: string = '';
  showHome: boolean = true;
  platIconTitleData: any = {};
  pageName: string = '';
  applicationId: string = '';
  menusData: any[] = [];
  curForm: any = {};
  formList: any[] = [];
  flowList: any[] = [];
  icon: string = '';

  tableTitle: string = '';

  /**
   * created
   */
  created() {
    this.initData();
    this.getPlatIconTitle();
  }
  initData(): void {
    let query = this.$route.query;
    this.formId = query.formId as string;
    this.pageName = query.name as string;
    this.icon = query.icon as string;
    this.applicationId = query.applicationId as string;
    this.buildMenusData();
  }

  buildMenusData(): void {
    // 固定数据
    let menusData = [];
    // 插入子节点
    Promise.all([this.getFormListData(), this.getFlowListData()]).then(res => {
      let formList = [];
      this.formList.map((item, index) => {
        if (item.link.status === '1') {
          let i = item;
          i.appCode = item.id;
          i.menuCode = item.id;
          i.menuId = item.id;
          i.menuName = item.link.displayName;
          i.menuPid = 'form-001';
          i.menuSort = index + 1;
          i.children = [];
          formList.push(i);
        }
      });
      let flowList = [];
      this.flowList.map((item, index) => {
        if (item.link.status === '1') {
          let i = item;
          i.appCode = item.id;
          i.menuCode = item.id;
          i.menuId = item.id;
          i.menuName = item.link.displayName;
          i.menuPid = 'flow-001';
          i.menuSort = index + 1;
          i.children = [];
          flowList.push(i);
        }
      });

      menusData = formList.concat(flowList);
      this.menusData = menusData;
    });
  }

  /**
   * 获取表单列表
   */
  async getFormListData(applicationId?: string): Promise<void> {
    let res: Record<any, any> = await AppApi.getApplicationLinkFormList({
      applicationId: applicationId ? applicationId : this.applicationId
    });
    if (res.code == '1' && res.data) {
      this.formList = res.data;
    } else {
      this.$message.error(res.message);
    }
  }
  // 获取流程列表
  async getFlowListData(applicationId?: string): Promise<void> {
    let res: Record<any, any> = await AppApi.getApplicationLinkProcessList({
      applicationId: applicationId ? applicationId : this.applicationId
    });
    if (res.code == '1' && res.data) {
      this.flowList = res.data;
    } else {
      this.$message.error(res.message);
    }
  }

  /**
   * 获取登录页面，布局页面配置
   * @param {number} param
   * @returns {number}
   */
  getPlatIconTitle(): void {
    this.platIconTitleData.icontitleDoorTitleColor = '$fff';
    this.platIconTitleData.icontitleDoorLogo = this.icon;
  }

  menuClick(data): void {
    let curForm = {
      code: data.link.code,
      id: data.linkId,
      type: data.menuPid == 'form-001' ? 'form' : 'flow'
    };
    this.tableTitle = data.menuName;
    this.showHome = false;
    this.curForm = curForm;
  }

  // 重新调整画布宽度
  resetFormViewerWidth() {
    setTimeout(() => {
      (this.$refs.homeViewer as any).buildElementWidth();
    }, 500);
  }
}
