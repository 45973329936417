var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    {
      staticStyle: { "border-radius": "6px", height: "100%" },
      attrs: {
        "body-style": { padding: "0px", borderRadius: "6px", height: "100%" },
      },
    },
    [
      _c("div", { staticClass: "boday-header" }, [
        _c(
          "div",
          { staticClass: "table-toolbar" },
          [
            _c("p", { staticClass: "table-toolbar-button" }, [
              _c("span", [
                _c("i", { staticClass: "el-icon-upload2" }),
                _vm._v("导出"),
              ]),
            ]),
            _c(
              "el-popover",
              {
                key: "fieldManagement-001",
                ref: "visiblepop",
                attrs: { placement: "bottom", width: "180", trigger: "click" },
              },
              [
                _c(
                  "p",
                  { staticStyle: { width: "100%" } },
                  [
                    _c(
                      "el-checkbox",
                      { on: { change: _vm.handleCheckAllChange } },
                      [_vm._v("全选")]
                    ),
                  ],
                  1
                ),
                _c(
                  "ul",
                  { staticClass: "field-management" },
                  _vm._l(_vm.tableHeaderField, function (item) {
                    return _c(
                      "li",
                      { key: item.key },
                      [
                        _c(
                          "el-checkbox",
                          {
                            model: {
                              value: item.display,
                              callback: function ($$v) {
                                _vm.$set(item, "display", $$v)
                              },
                              expression: "item.display",
                            },
                          },
                          [_vm._v(_vm._s(item.title))]
                        ),
                      ],
                      1
                    )
                  }),
                  0
                ),
                _c(
                  "p",
                  { staticStyle: { "text-align": "center", width: "100%" } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "primary" },
                        on: { click: _vm.saveFieldManagementFn },
                      },
                      [_vm._v("保存")]
                    ),
                  ],
                  1
                ),
                _c(
                  "p",
                  {
                    staticClass: "table-toolbar-button",
                    attrs: { slot: "reference" },
                    slot: "reference",
                  },
                  [
                    _c("span", [
                      _c("i", { staticClass: "el-icon-setting" }),
                      _vm._v("字段管理"),
                    ]),
                  ]
                ),
              ]
            ),
            _c(
              "p",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showAllSearchInput,
                    expression: "showAllSearchInput",
                  },
                ],
              },
              [
                _c("el-input", {
                  ref: "allSearchInput",
                  attrs: {
                    placeholder: "按Enter搜索",
                    "suffix-icon": "el-icon-search",
                  },
                  on: { blur: _vm.handleAllSearch },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.enterHandleAllSearch($event)
                    },
                  },
                  model: {
                    value: _vm.allSearchQuery,
                    callback: function ($$v) {
                      _vm.allSearchQuery = $$v
                    },
                    expression: "allSearchQuery",
                  },
                }),
              ],
              1
            ),
            !_vm.showAllSearchInput
              ? _c("p", { staticClass: "table-toolbar-button" }, [
                  _c("span", { on: { click: _vm.clickSearchButton } }, [
                    _c("i", { staticClass: "el-icon-search" }),
                    _vm._v("搜索"),
                  ]),
                ])
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "table-search-state" },
          _vm._l(_vm.tableSearchStateList, function (item, index) {
            return _c(
              "el-tag",
              {
                key: item.key,
                staticStyle: { "margin-right": "4px" },
                attrs: { closable: "", effect: "plain" },
                on: {
                  close: function ($event) {
                    return _vm.deleteSearchList(item, index)
                  },
                },
              },
              [
                _vm._v(
                  " " + _vm._s(item.title) + ": " + _vm._s(item.label) + " "
                ),
              ]
            )
          }),
          1
        ),
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tableLoading,
              expression: "tableLoading",
            },
          ],
          staticStyle: { height: "100%" },
          attrs: {
            "header-row-class-name": "table-header-style",
            border: "",
            data: _vm.tableData,
            "row-key": "id",
            "default-expand-all": "",
            "tree-props": { children: "children" },
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "index",
              label: _vm.$t("lang_serial_number"),
              align: "center",
              width: "55",
            },
          }),
          _vm._l(_vm.tableColumns, function (item, pIndex) {
            return _c("el-table-column", {
              key: item.key,
              attrs: {
                label: item.title,
                "min-width": "180",
                prop: item.key,
                align: "left",
                "show-overflow-tooltip": "",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function (ref) {
                      var column = ref.column
                      var $index = ref.$index
                      return [
                        _c(
                          "div",
                          { staticClass: "table-header-item-con" },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "table-header-button",
                                on: {
                                  click: function ($event) {
                                    return _vm.handlerSort(item)
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "el-icon-sort" }),
                                _vm._v(" " + _vm._s(column.label)),
                              ]
                            ),
                            _c(
                              "el-popover",
                              {
                                key: item.key + "001",
                                attrs: {
                                  placement: "bottom",
                                  width:
                                    item.dataType == "DATE" ? "350" : "240",
                                  trigger: "click",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  [
                                    item.dataType == "DATE"
                                      ? _c("el-date-picker", {
                                          key: item.key,
                                          attrs: {
                                            "value-format": "timestamp",
                                            type: "daterange",
                                            "range-separator": "至",
                                            "start-placeholder": "开始日期",
                                            "end-placeholder": "结束日期",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.changeDateSearch(item)
                                            },
                                          },
                                          model: {
                                            value: item.filterValue,
                                            callback: function ($$v) {
                                              _vm.$set(item, "filterValue", $$v)
                                            },
                                            expression: "item.filterValue",
                                          },
                                        })
                                      : _c("el-input", {
                                          attrs: {
                                            placeholder: "搜索 按下Enter确认",
                                          },
                                          nativeOn: {
                                            keyup: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              ) {
                                                return null
                                              }
                                              return _vm.enterTableSearchDebounce(
                                                item
                                              )
                                            },
                                          },
                                          model: {
                                            value: item.filterValue,
                                            callback: function ($$v) {
                                              _vm.$set(item, "filterValue", $$v)
                                            },
                                            expression: "item.filterValue",
                                          },
                                        }),
                                  ],
                                  1
                                ),
                                _c("ul", { staticClass: "popover-list" }, [
                                  _c(
                                    "li",
                                    [
                                      _c(
                                        "el-radio",
                                        {
                                          attrs: { label: "filledIn" },
                                          model: {
                                            value: item.filterType,
                                            callback: function ($$v) {
                                              _vm.$set(item, "filterType", $$v)
                                            },
                                            expression: "item.filterType",
                                          },
                                        },
                                        [_vm._v("已填写")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "li",
                                    [
                                      _c(
                                        "el-radio",
                                        {
                                          attrs: { label: "notFilledIn" },
                                          model: {
                                            value: item.filterType,
                                            callback: function ($$v) {
                                              _vm.$set(item, "filterType", $$v)
                                            },
                                            expression: "item.filterType",
                                          },
                                        },
                                        [_vm._v("未填写")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _c("i", {
                                  staticClass:
                                    "tab-header-item-icon el-icon-caret-bottom",
                                  attrs: { slot: "reference" },
                                  slot: "reference",
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _c("el-table-column", {
            attrs: {
              width: "100",
              label: _vm.$t("lang_operation"),
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.openApprove(row, "flow")
                          },
                        },
                      },
                      [_vm._v("流程图")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.openApprove(row)
                          },
                        },
                      },
                      [_vm._v("表单")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _c("el-pagination", {
        staticClass: "sj-pagination",
        attrs: {
          "current-page": _vm.pageIndex,
          "page-sizes": [10, 15, 20, 50],
          "page-size": _vm.pageSize,
          layout: "total, sizes, prev, pager, next, jumper",
          total: _vm.total,
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }