export interface originTableColumnsModel {
  dbField: boolean;
  display: boolean;
  html: boolean;
  index: number;
  key: string;
  search: boolean;
  title: string;
  val: boolean;
  value: string;
  visible: boolean;
  dataType: string;
  filterType?: 'filledIn' | 'notFilledIn' | null;
  filterValue?: string | Array<string>;
}
export interface conditionGroupsModel {
  first: boolean;
  key: string;
  logical: 'AND' | '';
  conditions: any[];
}

export const ConditionGroupsInit: Array<conditionGroupsModel> = [
  // group1 -- string ：包含; collect、tree：包含; boolean、number：等于
  { first: true, key: 'group1', logical: 'AND', conditions: [] },
  // group2 -- date： 单独条件组，两个条件
  {
    first: false,
    key: 'group2',
    logical: 'AND',
    conditions: []
  }
];

// 构建表头信息
export interface tableColumnsModel {
  display: boolean;
  html: boolean;
  index: string | number;
  key: string;
  title: string;
  dataType: string;
  sortFilter?: 'DEFAULT' | 'UP' | 'DOWN';
  filterType?: 'filledIn' | 'notFilledIn' | null;
  filterValue?: string | Array<string>;
}

export const TableColumnsInit: tableColumnsModel = {
  display: true,
  html: true,
  index: 0,
  key: '',
  title: '',
  dataType: '',
  sortFilter: 'DEFAULT',
  filterValue: ''
};

//表头升序 降序
export interface orderConfigsModel {
  order: boolean;
  orderByColumns: Array<orderConfigsItemModel>;
}
interface orderConfigsItemModel {
  key: string;
}
export const OrderConfigsInit: Array<orderConfigsModel> = [
  {
    order: true,
    orderByColumns: []
  },
  {
    order: false,
    orderByColumns: []
  }
];

export const WidgetDataTypeEnum: Record<string, string> = {
  STRING: 'STRING',
  NUMBER: 'NUMBER',
  DATE: 'DATE',
  COLLECTOR: 'COLLECTOR',
  TREE: 'TREE',
  BOOLEAN: 'BOOLEAN',
  FILE: 'FILE',
  DEFINITION: 'DEFINITION'
};

export const WidgetLogicEnum: Record<string, string> = {
  EQ: 'EQ',
  NE: 'NE',
  GT: 'GT',
  LT: 'LT',
  GE: 'GE',
  LE: 'LE',
  INC: 'INC',
  NINC: 'NINC',
  BELONG: 'BELONG',
  NBELONG: 'NBELONG'
};

export const WIDGET_TYPE_MAPS: any = {
  CARD: 'card',
  GRID: 'row',
  TABLE: 'table',
  DIALOG_LIST: 'dialogList',
  IMAGE: 'image',
  UPLOAD: 'upload'
};
