var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("oa-header", {
        attrs: {
          user: _vm.user,
          companys: _vm.companys,
          loginCompany: _vm.loginCompany,
          showDept: false,
          showLogout: true,
          depts: _vm.depts,
          loginDept: _vm.loginDept,
        },
        on: {
          switchCompany: _vm.handleSwitchCompany,
          switchDept: _vm.handleSwitchDept,
          logout: _vm.handleLogout,
          switchPwd: _vm.switchPwd,
        },
        scopedSlots: _vm._u(
          [
            {
              key: "left",
              fn: function () {
                return [
                  _vm._t("maxLeft", [
                    _c(
                      "div",
                      {
                        staticClass: "home-button",
                        on: {
                          click: function ($event) {
                            return _vm.goHome("/usuallyViwer")
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "el-icon-s-home" }, [
                          _vm._v("首页"),
                        ]),
                      ]
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
      _vm.isShowPwd
        ? _c("update-password", {
            attrs: { visible: _vm.isShowPwd },
            on: {
              "update:visible": function ($event) {
                _vm.isShowPwd = $event
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }