































import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import HeaderApi from '@/api/system/HeaderApi';
import UserUtil from '@/utils/UserUtil';
@Component({
  name: 'UpdatePassword'
})
export default class UpdatePassword extends Vue {
  // ref
  @Ref() readonly formRef;
  @Prop({ default: false }) private visible!: boolean;
  //   表单数据
  formData: any = {};
  //   验证规则
  formRules: Record<any, any> = {
    password: {
      required: true,
      message: this.$t('lang_please_entry_new_password'),
      trigger: 'blur'
    },
    oldPassword: {
      required: true,
      message: this.$t('lang_please_entry_old_password'),
      trigger: 'blur'
    }
  };

  save() {
    // 验证表单
    Promise.all([this.formRef.validate()]).then(() => {
      this.formData.userId = UserUtil.getUser().id;
      HeaderApi.password(this.formData).then((res: Record<any, any>) => {
        if (res.code == '1') {
          this.$message.success(res.message);
          // 关闭弹窗
          this.handleCloseDialog();
        } else {
          this.$message.error(res.message);
        }
      });
    });
  }

  // 关闭弹窗
  handleCloseDialog() {
    this.$emit('success', {});
    this.updateVisible(false);
  }

  // 监听更新显示和隐藏
  updateVisible(value) {
    this.$emit('update:visible', value);
  }

  // 获取显示值
  get dialogVisible() {
    return this.visible;
  }

  // 设置显示值
  set dialogVisible(newValue) {
    this.updateVisible(false);
  }
}
