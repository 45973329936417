var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "wrap-container" },
    [
      _c("left-menu", {
        ref: "leftMenu",
        attrs: {
          customTitle: _vm.$t(_vm.pageName),
          asideLogoUrl: "/river-fs/file/view?state=public&clientId=111&fileId=",
          platIconTitleData: _vm.platIconTitleData,
          menus: _vm.menusData,
        },
        on: {
          clickCollapse: _vm.resetFormViewerWidth,
          menuClick: _vm.menuClick,
        },
      }),
      _c(
        "el-container",
        { attrs: { direction: "vertical" } },
        [
          _c("my-header", {
            on: {
              goHome: function ($event) {
                _vm.showHome = true
                _vm.$refs.leftMenu.cancelActive()
              },
            },
          }),
          _c("el-main", { staticStyle: { padding: "0" } }, [
            _c(
              "div",
              { staticClass: "con" },
              [
                _vm.formId && _vm.showHome
                  ? _c("FormViewer", {
                      key: _vm.formId,
                      ref: "homeViewer",
                      attrs: { formContainer: "transparent" },
                    })
                  : _vm.curForm.type == "form"
                  ? _c("MyFormDataManager", {
                      key: _vm.curForm.id,
                      attrs: { tableTitle: _vm.tableTitle, form: _vm.curForm },
                    })
                  : _c("FlowDataManager", {
                      key: _vm.curForm.id,
                      attrs: { tableTitle: _vm.tableTitle, form: _vm.curForm },
                    }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }