















































































































































import { Component, Prop, Watch, Mixins } from 'vue-property-decorator';
import { topicHistory } from '@/api/flow-design/FlowApi';
import { saveFormConfigsData } from '@/api/form-design/FormApi';
import DateUtil from '@/utils/DateUtil';
//表头数据过滤
import HeaderFilterData from '../components-mixins/HeaderFilterData';
import { buildFormWidgetMap, getComponentInfo } from '@/components/form-design/utils';
import _ from 'lodash';
import { originTableColumnsModel, conditionGroupsModel, ConditionGroupsInit, tableColumnsModel } from '../form-data-manager/TableType';

import { getFlow } from '@/api/flow-design/FlowApi';
import { getFormConfig } from '@/api/form-design/FormApi';
@Component({
  name: 'FormTable'
})
export default class extends Mixins(HeaderFilterData) {
  // 选择的部门、用户列表
  @Prop({ default: 'waitList' }) tableType;
  @Prop({
    default() {
      return {};
    }
  })
  form!: any;
  showAllSearchInput: boolean = false;
  formConfig: any = {}; // 获取组件的总数据

  /******************************************************************
   *页面展示类型数据
   ******************************************************************/
  originTableColumns: Array<originTableColumnsModel> = []; // 原始表头数据
  tableColumns: Array<tableColumnsModel> = []; // 表格的动态列
  tableHeaderField: any[] = []; //表头字段管理 用于过滤
  multipleSelection: any[] = []; //列表选择的值
  tableSearchStateList: any[] = []; //表头搜索时的状态栏
  allSearchQuery: string = ''; //表头 搜索框的绑定值
  /******************************************************************
   *分页 表格加载数据
   ******************************************************************/
  tableLoading: boolean = false;
  tableData: any[] = [];

  /******************************************************************
   *请求接口的条件值
   ******************************************************************/
  conditionGroups: Array<conditionGroupsModel> = _.cloneDeep(ConditionGroupsInit);

  @Watch('tableType')
  formCodeWatcher(val) {
    this.initData();
    this.getData();
  }

  created() {
    this.getTableHeader();
    this.getData();
  }

  initData() {
    let columns = _.cloneDeep(this.originTableColumns).filter(item => item.display);
    this.tableColumns = [];
    this.$nextTick(() => {
      this.tableColumns = columns;
    });

    this.tableSearchStateList = [];
    this.allSearchQuery = '';
    this.tableData = [];
    this.conditionGroups = _.cloneDeep(ConditionGroupsInit);
  }

  // 获取表头数据
  async getTableHeader() {
    // 先获取formId
    let getFlowRes: any = await getFlow(this.form.id, {});
    if (getFlowRes.code == '1') {
      const formId = getFlowRes.data.customBpmModel.config?.formId;
      if (!formId) return;
      //再调用single接口获取 表头数据
      getFormConfig({ id: formId }).then((res: any) => {
        if (res.code == '1' && res.data) {
          this.formConfig = res.data;
          let formWidgetMap = buildFormWidgetMap(this.formConfig.container.components);
          let columns = this.buildTableColumn(formWidgetMap);
          this.originTableColumns = _.cloneDeep(columns);
          this.tableColumns = columns.filter(item => item.display);
          this.tableHeaderField = this.initfilterTableColumn(formWidgetMap);
        }
      });
    }
  }

  getDate(row) {
    return DateUtil.format(new Date(row.completedDate), 'yyyy-MM-dd');
  }
  // 跳转
  openApprove(row, seeType = 'form') {
    let id = row.id;
    // 待办
    let path = this.$router.resolve({ path: '/front2/undo', query: { id, seeType } });
    window.open(path.href, '_blank');
  }

  //获取表格数据源
  getData(filterSearch?) {
    let params: any = {
      processCode: this.form.code,
      page: {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      }
    };
    if (filterSearch) params = this.buildFilterSearchParams(params, this.conditionGroups, this.tableColumns);

    this.tableData = []; //清空表格数据
    if (this.tableType == 'waitList') {
    }
    if (this.tableType == 'doneList') {
      return new Promise((resolve, reject) => {
        this.getDoneListData(resolve, reject, params);
      });
    }
    if (this.tableType == 'possibilityList') {
    }
    if (this.tableType == 'startedList') {
    }
  }

  // 获取已办
  getDoneListData(resolve, reject, params) {
    topicHistory(params)
      .then(res => {
        this.resHandler(res, resolve, reject);
      })
      .catch(err => {
        this.$message.error(err);
        reject(err);
      });
  }

  resHandler(res, resolve, reject) {
    if (res.code == 1) {
      if (res.data && res.data.list && res.data.list.length) {
        // this.buildTableData(res.data);
      } else {
        this.pageIndex = 1;
        this.pageSize = 10;
        this.total = 0;
      }
      resolve('ok');
    } else {
      this.$message.error(res.message);
    }
  }

  changeDateSearch(current) {
    this.enterTableSearch(current);
  }

  // 增加
  addTableSearchStateList(current, flg?: string) {
    let label = _.cloneDeep(current.filterValue);
    if (Array.isArray(label)) {
      let data1 = new Date(label[0]);
      label[0] = DateUtil.format(data1, 'yyyy-MM-dd');
      let data2 = new Date(label[1]);
      label[1] = DateUtil.format(data2, 'yyyy-MM-dd');
      label = label.join('-');
    }
    let type = flg ? flg : 'SEARCH';
    this.tableSearchStateList.push({
      key: current.key + `-${type}`,
      title: current.title,
      label: label
    });
  }
  // 更新
  toUpdateTableSearchStateList(updateVal, index) {
    this.tableSearchStateList[index].label = updateVal;
  }

  private enterTableSearchDebounce = _.debounce(this.enterTableSearch, 500, {
    leading: true,
    trailing: false
  });

  //点击表头 搜索按下Enter
  // v当前输入的值 i 索引
  enterTableSearch(current?) {
    // 初始化条件值 conditionGroups用于请求接口
    this.conditionGroups = _.cloneDeep(ConditionGroupsInit);
    let sortFlg = false;
    // 处理数据
    this.tableColumns.map((item, index) => {
      let filterValue = _.cloneDeep(item.filterValue);
      let dataType = this.getCompare(item.dataType);
      if (item.sortFilter != 'DEFAULT') sortFlg = true;
      if (filterValue && dataType != 'DATE') {
        let conditionsItem = {
          compare: dataType,
          filter: {
            type: item.dataType
          },
          first: index === 0 ? true : false,
          key: item.key,
          source: 'INPUT',
          logical: 'AND',
          tableName: 'formModelData',
          value: [{ type: 'FIXED_VALUE', value: filterValue }]
        };
        this.conditionGroups[0].conditions.push(conditionsItem);
      } else if (filterValue && filterValue.length) {
        // 等于DATE时
        let item1 = {
          compare: '',
          first: index === 0 ? true : false,
          key: item.key,
          filter: {
            type: item.dataType
          },
          source: 'INPUT',
          logical: 'AND',
          tableName: 'formModelData',
          value: [{ type: 'FIXED_VALUE', value: '' }]
        };
        let item2 = _.cloneDeep(item1);
        item1.compare = 'GE';
        item1.value[0].value = filterValue[0];
        item2.compare = 'LE';
        item2.value[0].value = filterValue[1];
        this.conditionGroups[1].conditions.push(item1, item2);
      }
    });
    // 搜索
    if (this.conditionGroups[0].conditions.length || this.conditionGroups[1].conditions.length || sortFlg) {
      this.getData('filterSearch');
      let key = '';
      if (!current) return;
      this.tableSearchStateList.map((item, index) => {
        if (item.key == current.key + '-SEARCH') {
          key = item.key;
          // 时间
          let label = _.cloneDeep(current.filterValue);
          if (Array.isArray(label)) {
            let str = '';
            let data1 = new Date(label[0]);
            label[0] = DateUtil.format(data1, 'yyyy-MM-dd');
            let data2 = new Date(label[1]);
            label[1] = DateUtil.format(data2, 'yyyy-MM-dd');
            label = label.join('-');
          }
          this.toUpdateTableSearchStateList(label, index);
          return;
        }
      });
      if (!key) this.addTableSearchStateList(current, 'SEARCH');
    } else {
      this.getData();
    }
  }

  // 移除搜索条件
  deleteSearchList(current, index) {
    this.tableColumns.map(item => {
      if (item.key + '-SEARCH' == current.key) {
        item.filterValue = '';
        return;
      } else if (item.key + '-SORT' == current.key) {
        item.sortFilter = 'DEFAULT';
        return;
      }
    });
    // 移除
    this.tableSearchStateList.splice(index, 1);
    (this.$refs.visiblepop as any).doClose();
    this.enterTableSearch();
  }

  //排序
  handlerSort(item) {
    if (item.sortFilter == 'DEFAULT') {
      this.handlerSortAdd(item);
    } else {
      this.handlerSortUpdate(item);
    }
  }
  // 排序新增
  handlerSortAdd(item) {
    let addFlg = true;
    this.tableSearchStateList.map(item => {
      if (item.key == item.key + '-SORT') {
        addFlg = false;
        return;
      }
    });
    if (addFlg) {
      item.sortFilter = 'UP';
      this.addTableSearchStateList(
        {
          key: item.key,
          title: item.title,
          filterValue: '升序'
        },
        'SORT'
      );
      this.enterTableSearch();
    } else {
      console.log('错误添加');
      // this.handlerSortUpdate(item);
    }
  }
  // 字段排序更新
  handlerSortUpdate(item) {
    if (item.sortFilter == 'UP') {
      item.sortFilter = 'DOWN';
      this.tableSearchStateList.map((i, index) => {
        if (i.key == item.key + '-SORT') {
          this.toUpdateTableSearchStateList('降序', index);
          return;
        }
      });
      this.enterTableSearch();
      return;
    } else if (item.sortFilter == 'DOWN') {
      item.sortFilter = 'UP';
      this.tableSearchStateList.map((i, index) => {
        if (i.key == item.key + '-SORT') {
          this.toUpdateTableSearchStateList('升序', index);
          return;
        }
      });
      this.enterTableSearch();
      return;
    }
  }

  //字段管理
  handleCheckAllChange(val) {
    this.tableHeaderField.map(item => {
      item.display = val ? true : false;
    });
  }
  //字段管理 点击保存
  saveFieldManagementFn(): void {
    let originMap = {};
    this.originTableColumns.map(item => {
      originMap[item.key] = item;
    });
    let result = [];
    let columns = this.tableHeaderField;
    columns = columns.map(item => {
      if (item.display) {
        let formConfigitem = getComponentInfo(this.formConfig.container.components, item.key);
        formConfigitem.display = true;
        result.push(originMap[item.key]);
      } else {
        let formConfigitem = getComponentInfo(this.formConfig.container.components, item.key);
        formConfigitem.display = false;
      }
    });
    this.saveColumnConfig();
    this.tableColumns = result;
    (this.$refs.visiblepop as any).doClose();
  }
  saveColumnConfig() {
    saveFormConfigsData(this.formConfig).then((res: Record<string, any>) => {
      if (res.code == '1') {
        this.$message.success(res.message);
      } else {
        this.$message.error(res.message);
      }
    });
  }

  // 点击搜索 显示搜索框并使input获取焦点
  clickSearchButton() {
    this.showAllSearchInput = true;
    this.$nextTick(() => {
      (this.$refs.allSearchInput as any).focus();
    });
  }
  handleAllSearch() {
    if (this.allSearchQuery) return;
    this.showAllSearchInput = false;
  }
  enterHandleAllSearch(e) {
    //enter后失去焦点 触发handleAllSearch函数
    if (e) {
      e.target.blur();
    }
  }

  // 当前页数修改时
  handleSizeChange(pageSize) {
    this.pageSize = pageSize;
    this.getData('filterSearch');
  }
  // 当前页码修改时
  handleCurrentChange(pageIndex) {
    this.pageIndex = pageIndex;
    this.getData('filterSearch');
  }

  handleSelectionChange(val): void {
    this.multipleSelection = val;
  }
}
