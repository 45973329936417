<template>
  <div>
    <oa-header
      :user="user"
      :companys="companys"
      :loginCompany="loginCompany"
      :showDept="false"
      :showLogout="true"
      :depts="depts"
      :loginDept="loginDept"
      @switchCompany="handleSwitchCompany"
      @switchDept="handleSwitchDept"
      @logout="handleLogout"
      @switchPwd="switchPwd"
    >
      <template #left>
        <slot name="maxLeft">
          <div class="home-button" @click="goHome('/usuallyViwer')">
            <i class="el-icon-s-home">首页</i>
          </div>
        </slot>
        <!-- <img
        style="position: absolute; top: 6px; left: 14px; width: 50px"
        :src="pubPreviewUrl + '?fileId=' + logoId + '&token=' + token"
        alt=""
      /> -->
        <!-- <div class="title" v-if="isEn">
        <span>{{ $t(logoTitle) }}</span>
      </div>
      <div class="en-title" v-else>
        <span>{{ $t(logoTitle) }}</span>
      </div> -->

        <!-- <div style="position: absolute; top: 0px; right: 300px; cursor: pointer">
        <el-dropdown class="switch-dropdown" trigger="click" @command="changeLanguage">
          <div style="color: white">
            {{ currentLanguage }}
            <i class="el-icon-arrow-down el-icon--right" style="font-size: 14px"></i>
          </div>
          <el-dropdown-menu slot="dropdown" style="border: 0px">
            <el-dropdown-item v-for="(item, index) in languageList" :key="index"
              :command="{ code: item.code, id: item.id }">
              {{ item.name }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div> -->
      </template>
    </oa-header>
    <!-- 修改密码弹框 -->
    <update-password :visible.sync="isShowPwd" v-if="isShowPwd"></update-password>
  </div>
</template>
<script>
import StorageUtil from 'global-ui/packages/utils/StorageUtil';
import UserUtil from '@/utils/UserUtil';
import HeaderApi from '@/api/system/HeaderApi';
import { mapGetters } from 'vuex';
import { getLanguageTypeQueryList } from '@/api/system/LanguageApi';
import { setI18nCookie, setI18nLanguage, curLangStorageKey } from '@/lang';
import TokenUtil from 'global-ui/packages/utils/TokenUtil';
import { THEME_DATA } from '@/mock/ThemeMock';
import OaHeader from '@/components/oa-header/main.vue';
import UpdatePassword from './update-password.vue';
import { getCompanys } from '@/api/system/OrganizationApi';

import eventBus from '@/eventBus/index';

export default {
  name: 'RiverHeader',
  components: { OaHeader, UpdatePassword },
  data() {
    return {
      user: {},
      companys: [],
      loginCompany: {},
      depts: [],
      loginDept: {},
      languageList: [],
      currentLanguage: '',
      isShowPwd: false,
      pubPreviewUrl: '/river-fs/file/view', // 图片组件预览地址
      logoId: '', //logo
      logoTitle: '',
      isFlow: false, //流程切换表单
      token: TokenUtil.getToken()
    };
  },
  created() {
    // 获取初始化数据
    this.initData();
    this.logoTitle = THEME_DATA.icontitleDoorTitle;

    let path = this.$route.path;
    if (path == '/flow/design') {
      this.isFlow = true;
    }
    this.loginCompany = this.buildLoginCompanyFromHeader();
    this.getUserAllCompanys();
  },
  props: {
    clientId: {
      type: String,
      default: process.env.VUE_APP_CLIENT_ID
    }
  },
  computed: {
    ...mapGetters(['menus', 'loginType', 'companyData']),
    isEn() {
      const str = this.$t(this.logoTitle);
      for (let i in str) {
        let asc = str.charCodeAt(i);
        if ((asc >= 65 && asc <= 90) || (asc >= 97 && asc <= 122)) {
          return false;
        }
      }
      return true;
    }
  },
  methods: {
    /**
     * 获取登录人所有的公司
     */
    getUserAllCompanys() {
      getCompanys({ userId: UserUtil.getUser().id }).then(res => {
        if (res.code == '1') {
          this.companys = this.buildCompanyListFromHeader(res.data);
        } else {
          this.$message.error(res.$message);
        }
      });
    },
    /**
     * 切换到用户端
     */
    goToFront() {
      this.$router.push({ path: '/home' });
    },
    /**
     * 获取菜单数据
     * @param {number} param
     * @returns {number}
     */
    initData() {
      this.user = this.buildUserForHeader();
      // this.companys = this.buildCompanyListFromHeader();
      // this.loginCompany = this.buildLoginCompanyFromHeader();
      this.depts = this.buildDeptListFromHeader();
      this.loginDept = this.buildLoginDeptFromHeader();

      // 获取语言数据
      this.getLanguageList();
    },

    /**
     * 组织头部需要的用户信息
     * @returns {Object} user
     */
    buildUserForHeader() {
      let user = UserUtil.getUser();
      return {
        userId: user.id,
        userFullNameCn: user.name
      };
    },

    /**
     * 组织头部需要的公司列表信息
     * @returns {Object} company
     */
    buildCompanyListFromHeader(list) {
      let companyList = list;
      // this.logoId = companyList[0].logo;
      return companyList.map(item => {
        return {
          companyId: item.id,
          companyName: item.shortName
        };
      });
    },
    /**
     * 组织头部需要的公司信息
     * @returns {Object} company
     */
    buildLoginCompanyFromHeader() {
      let loginCompanmy = this.companyData;
      if (loginCompanmy) {
        return {
          companyId: loginCompanmy.id,
          companyName: loginCompanmy.shortName
        };
      } else {
        return {};
      }
    },

    /**
     * 组织头部需要的公司信息
     * @returns {Object} company
     */
    buildDeptListFromHeader() {
      let result = [];
      // let deptList = UserUtil.getDeptList();
      // if (deptList) {
      //   result = deptList.map(item => {
      //     return {
      //       deptId: item.deptId,
      //       deptName: item.deptNameCn
      //     };
      //   });
      // }
      return result;
    },

    /**
     * 组织头部需要的部门信息
     * @returns {Object} dept
     */
    buildLoginDeptFromHeader() {
      // let loginDept = UserUtil.getLoginDept();
      // if (loginDept) {
      //   return {
      //     deptId: loginDept.deptId,
      //     deptName: loginDept.deptNameCn
      //   };
      // }
      return {};
    },

    /**
     * 修改密码
     */
    switchPwd() {
      this.isShowPwd = true;
    },
    /**
     * 切换公司
     * @param {number} param
     * @returns {number}
     */
    handleSwitchCompany(companyId) {
      let params = {
        clientId: this.clientId,
        companyId: companyId
      };
      StorageUtil.clearSessionStorage();
      window.location.href =
        process.env.VUE_APP_SERVER_CODE +
        'auth/switchAccount?clientId=' +
        params.clientId +
        '&companyId=' +
        params.companyId +
        '&token=' +
        this.token;
    },
    /**
     * 切换部门
     * @param {number} param
     * @returns {number}
     */
    handleSwitchDept(deptId) {
      // let data = {
      //   companyId: UserUtil.getLoginCompany().companyId,
      //   account: UserUtil.getUser().account,
      //   deptId
      // };
      // HeaderApi.switchCompanyDept(data).then(res => {
      //   StorageUtil.setSessionStorage('token', res.data.token);
      //   window.location.reload();
      // });
    },
    /**
     * 退出系统
     * @param {number} param
     * @returns {number}
     */
    handleLogout() {
      if (this.loginType === '1') {
        StorageUtil.clearSessionStorage();
        let clientId = process.env.VUE_APP_CLIENT_ID;
        let url = `${process.env.VUE_APP_SERVER_CODE}auth/login/out?clientId=${clientId}`;
        window.location.href = url;
      } else if (this.loginType === '2') {
        HeaderApi.logout({}).then(res => {
          StorageUtil.clearSessionStorage();
          this.$router.push('/login');
        });
      }
    },

    /**
     * 获取语言列表
     */
    getLanguageList() {
      getLanguageTypeQueryList().then(res => {
        if (res.code == '1') {
          this.languageList = res.data;
          let currentLangCode = StorageUtil.getLocalStorage(curLangStorageKey);
          if (currentLangCode) {
            let filter = this.languageList.filter(item => item.code === currentLangCode);
            if (filter.length > 0) {
              this.currentLanguage = filter[0].name;
            }
          }
        }
      });
    },

    /**
     * 更改语言
     * @param code
     */
    changeLanguage({ code, id }) {
      // 更换语言
      setI18nLanguage(code);
      setI18nCookie(code);
      this.$store.dispatch('home/changeLang', code);
      this.currentLanguage = this.languageList.filter(item => item.id === id)[0].name;
      // 刷新当前页
      window.location.reload();
    },

    // 通过 eventsBus传递事件     在此路由的组件下使用 /flow/design
    changeShow(val) {
      eventBus.$emit('changeShow', val);
    },

    goHome() {
      this.$emit('goHome');
    },

    goBack() {
      if (['/flow/design', '/form/design', '/form/history', '/flow/history'].includes(this.$route.path)) {
        sessionStorage.setItem('designId', JSON.stringify(this.$route.query));
      }
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="scss" scoped>
.top-button {
  position: absolute;
  right: 300px;
  width: 50%;
  height: 60px;
  top: 0;
  display: flex;
  flex-direction: row-reverse;

  > div {
    cursor: pointer;
    margin-left: 20px;
  }
}

.title {
  position: absolute;
  height: 60px;
  left: 80px;
  top: 0;

  span {
    font-size: var(--rootFontSize2);
    font-weight: 600;
    letter-spacing: 6px;
  }
}

.en-title {
  position: absolute;
  height: 60px;
  left: 80px;
  top: 0;

  span {
    font-size: var(--rootFontSize2);
    font-weight: 600;
  }
}
.home-button {
  cursor: pointer;
  margin-left: 90px;
  letter-spacing: 2px;
  i {
    font-size: 18px;
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
