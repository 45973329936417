import { Component, Vue } from 'vue-property-decorator';
@Component({
  name: 'UsuallyApplicationUtil'
})
export default class extends Vue {
  ellipsisText(val: string, maxText: number): string {
    if (val.length) {
      if (val.length > maxText) {
        return val.substring(0, maxText) + '...';
      }
    }
    return val;
  }
}
