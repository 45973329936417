import { Vue, Component } from 'vue-property-decorator';
import { tableColumnsModel, TableColumnsInit } from '../form-data-manager/TableType';
import _ from 'lodash';
import { OrderConfigsInit } from '../form-data-manager/TableType';
interface RequestParams {
  processCode: string;
  page: {
    pageIndex: number;
    pageSize: number;
  };
  conditionGroups?: any;
  orderConfigs?: any;
}
@Component({
  name: 'HeaderFilterData'
})
export default class extends Vue {
  pageIndex: number = 1; //当前页面
  pageSize: number = 10; // 当前页数
  total: number = 0; // 总条数
  tableData: any = []; // 表单分页数据
  formDialogVisible: boolean = false;

  tableHeaderFilterFieldsList: Array<string> = ['card', 'row', 'tab', 'table'];

  //初始化 表头字段管理
  initfilterTableColumn(tableData): Array<any> {
    let result = [];
    Object.keys(tableData).map(keys => {
      if (!['formId', 'formDataId'].includes(tableData[keys].key)) {
        let item = tableData[keys];
        if (!this.tableHeaderFilterFieldsList.includes(item.compType)) {
          let newCol: {
            display: string;
            title: string;
            key: string;
          } = {
            display: '',
            title: '',
            key: ''
          };
          newCol.display = item.display;
          newCol.title = item.displayName;
          newCol.key = item.compCode;
          result.push(newCol);
        }
      }
    });
    return result;
  }

  /**
   * 构建表格表头
   * @param tableData 表头数据
   */
  buildTableColumn(tableData): Array<any> {
    let columns = [];
    if (tableData) {
      Object.keys(tableData).map((keys: any, index) => {
        let item = tableData[keys];
        if (!this.tableHeaderFilterFieldsList.includes(item.compType)) {
          let col: tableColumnsModel = _.cloneDeep(TableColumnsInit);
          col.index = String(index);
          col.filterValue = '';
          col.html = item.properties.html;
          col.key = item.compCode;
          col.title = item.displayName;
          col.dataType = item.dataType;
          col.display = item.display;
          col.sortFilter = 'DEFAULT';
          columns.push(col);
        }
      });
      return columns;
    }
  }

  /**
   * 构建表格数据
   */
  buildTableData(pageInfo): Array<any> {
    let result: any[] = [];
    let tableData = pageInfo.list;
    tableData.forEach(row => {
      let item = {};
      row.columns.forEach(column => {
        item[column.key] = column.value;
      });
      result.push(item);
    });
    this.tableData = result;
    this.pageIndex = pageInfo.pageNum;
    this.pageSize = pageInfo.pageSize;
    this.total = pageInfo.total;
    return result;
  }

  /**
   * 构建请求参数
   * @param params 需要构建的请求参数
   * @param conditionGroups params中的条件值
   * @param tableColumns 表格的动态列
   * @returns 返回构建结果
   */
  buildFilterSearchParams(params: RequestParams, conditionGroups?, tableColumns?): RequestParams {
    const result = params;
    if (conditionGroups[1].conditions.length && conditionGroups[0].conditions.length) {
      params.conditionGroups = conditionGroups;
    } else if (conditionGroups[1].conditions.length) {
      params.conditionGroups = [conditionGroups[1]];
    } else if (conditionGroups[0].conditions.length) {
      params.conditionGroups = [conditionGroups[0]];
    }
    // 构建排序 搜索字段
    let orderConfigs = this.bulidSortParams(tableColumns);
    if (orderConfigs) params.orderConfigs = orderConfigs;

    return result;
  }

  bulidSortParams(tableColumns) {
    let rules = _.cloneDeep(OrderConfigsInit);
    tableColumns.map(item => {
      if (item.sortFilter == 'UP') {
        rules[0].orderByColumns.push({ key: item.key });
      }
      if (item.sortFilter == 'DOWN') {
        rules[1].orderByColumns.push({ key: item.key });
      }
    });
    if (rules[0].orderByColumns.length && rules[1].orderByColumns.length) {
      return rules;
    } else if (rules[0].orderByColumns.length) {
      return [rules[0]];
    } else if (rules[1].orderByColumns.length) {
      return [rules[1]];
    }
    return null;
  }

  // 获取比较符号
  getCompare(dataType): string {
    // group1 -- string ：包含; collect、tree：包含; boolean、number：等于
    // group2 -- date： 单独条件组，两个条件
    // 比较符枚举
    let compare = {
      EQ: 'EQ', //等于
      INC: 'INC', //包含
      GE: 'GE', //大于
      LE: 'LE' //小于
    };
    if (dataType == 'BOOLEAN' || dataType == 'NUMBER') return compare.EQ;
    if (dataType == 'TREE' || dataType == 'COLLECT') return compare.INC;
    if (dataType == 'STRING') return compare.INC;
    if (dataType == 'DATE') return dataType;
  }
}
