var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100%" } },
    [
      _c(
        "div",
        { staticClass: "header-tab" },
        [
          _c("div", { staticClass: "header-tab-title" }, [
            _vm._v(_vm._s(_vm.tableTitle)),
          ]),
          _c(
            "div",
            { staticClass: "header-tab-button" },
            [
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: { effect: "dark", content: "导入", placement: "top" },
                },
                [
                  _c("span", { staticClass: "header-tab-button-icon" }, [
                    _c("i", { staticClass: "el-icon-folder-add" }),
                  ]),
                ]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.handleAdd },
                },
                [_vm._v("发起")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "warning",
                    size: "small",
                    disabled: _vm.multipleSelection.length != 1,
                  },
                  on: { click: _vm.handleEidt },
                },
                [_vm._v("编辑")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "danger",
                    size: "small",
                    disabled: !_vm.multipleSelection.length,
                  },
                  on: { click: _vm.handleDelete },
                },
                [_vm._v("删除")]
              ),
            ],
            1
          ),
          _c(
            "el-tabs",
            {
              attrs: { type: "card" },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c("el-tab-pane", { attrs: { name: "first" } }, [
                _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                  _c("i", {
                    staticClass: "el-icon-date",
                    staticStyle: { margin: "2px" },
                  }),
                  _vm._v("待办视图"),
                ]),
              ]),
              _c("el-tab-pane", { attrs: { name: "second" } }, [
                _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                  _c("i", {
                    staticClass: "el-icon-s-claim",
                    staticStyle: { margin: "2px" },
                  }),
                  _vm._v("已办视图"),
                ]),
              ]),
              _c("el-tab-pane", { attrs: { name: "third" } }, [
                _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                  _c("i", {
                    staticClass: "el-icon-folder-checked",
                    staticStyle: { margin: "2px" },
                  }),
                  _vm._v("结办视图"),
                ]),
              ]),
              _c("el-tab-pane", { attrs: { name: "fourth" } }, [
                _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                  _c("i", {
                    staticClass: "el-icon-position",
                    staticStyle: { margin: "2px" },
                  }),
                  _vm._v("我的发起"),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "body-tab" },
        [
          _c(
            "el-card",
            {
              staticStyle: { "border-radius": "6px", height: "100%" },
              attrs: {
                "body-style": {
                  padding: "0px",
                  borderRadius: "6px",
                  height: "100%",
                },
              },
            },
            [
              _c("div", { staticClass: "boday-header" }, [
                _c(
                  "div",
                  { staticClass: "table-toolbar" },
                  [
                    _c("p", { staticClass: "table-toolbar-button" }, [
                      _c("span", [
                        _c("i", { staticClass: "el-icon-upload2" }),
                        _vm._v("导出"),
                      ]),
                    ]),
                    _c(
                      "el-popover",
                      {
                        key: "fieldManagement-001",
                        ref: "visiblepop",
                        attrs: {
                          placement: "bottom",
                          width: "180",
                          trigger: "click",
                        },
                      },
                      [
                        _c(
                          "p",
                          { staticStyle: { width: "100%" } },
                          [
                            _c(
                              "el-checkbox",
                              { on: { change: _vm.handleCheckAllChange } },
                              [_vm._v("全选")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "ul",
                          { staticClass: "field-management" },
                          _vm._l(_vm.tableHeaderField, function (item) {
                            return _c(
                              "li",
                              { key: item.key },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    model: {
                                      value: item.display,
                                      callback: function ($$v) {
                                        _vm.$set(item, "display", $$v)
                                      },
                                      expression: "item.display",
                                    },
                                  },
                                  [_vm._v(_vm._s(item.title))]
                                ),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                        _c(
                          "p",
                          {
                            staticStyle: {
                              "text-align": "center",
                              width: "100%",
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "primary" },
                                on: { click: _vm.saveFieldManagementFn },
                              },
                              [_vm._v("保存")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "p",
                          {
                            staticClass: "table-toolbar-button",
                            attrs: { slot: "reference" },
                            slot: "reference",
                          },
                          [
                            _c("span", [
                              _c("i", { staticClass: "el-icon-setting" }),
                              _vm._v("字段管理"),
                            ]),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showAllSearchInput,
                            expression: "showAllSearchInput",
                          },
                        ],
                      },
                      [
                        _c("el-input", {
                          ref: "allSearchInput",
                          attrs: {
                            placeholder: "按Enter搜索",
                            "suffix-icon": "el-icon-search",
                          },
                          on: { blur: _vm.handleAllSearch },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.enterHandleAllSearch($event)
                            },
                          },
                          model: {
                            value: _vm.allSearchQuery,
                            callback: function ($$v) {
                              _vm.allSearchQuery = $$v
                            },
                            expression: "allSearchQuery",
                          },
                        }),
                      ],
                      1
                    ),
                    !_vm.showAllSearchInput
                      ? _c("p", { staticClass: "table-toolbar-button" }, [
                          _c("span", { on: { click: _vm.clickSearchButton } }, [
                            _c("i", { staticClass: "el-icon-search" }),
                            _vm._v("搜索"),
                          ]),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "table-search-state" },
                  _vm._l(_vm.tableSearchStateList, function (item, index) {
                    return _c(
                      "el-tag",
                      {
                        key: item.key,
                        staticStyle: { "margin-right": "4px" },
                        attrs: { closable: "", effect: "plain" },
                        on: {
                          close: function ($event) {
                            return _vm.deleteSearchList(item, index)
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(item.title) +
                            ": " +
                            _vm._s(item.label) +
                            " "
                        ),
                      ]
                    )
                  }),
                  1
                ),
              ]),
              _vm.form.code
                ? [
                    _c(
                      "el-table",
                      {
                        ref: "tableRef",
                        attrs: {
                          "header-row-class-name": "table-header-style",
                          border: "",
                          stripe: "",
                          data: _vm.tableData,
                          height: "calc(100% - 100px)",
                          "row-key": "id",
                          "default-expand-all": "",
                          "tree-props": { children: "children" },
                        },
                        on: { "selection-change": _vm.handleSelectionChange },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            type: "selection",
                            width: "55",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$t("lang_serial_number"),
                            align: "center",
                            type: "index",
                            width: "150",
                          },
                        }),
                        _vm._l(_vm.tableColumns, function (item, pIndex) {
                          return _c("el-table-column", {
                            key: item.key,
                            attrs: {
                              label: item.title,
                              prop: item.key,
                              align: "left",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "header",
                                  fn: function (ref) {
                                    var column = ref.column
                                    var $index = ref.$index
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "table-header-item-con",
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "table-header-button",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handlerSort(item)
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "el-icon-sort",
                                              }),
                                              _vm._v(
                                                " " + _vm._s(column.label)
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "el-popover",
                                            {
                                              key: item.key + "001",
                                              attrs: {
                                                placement: "bottom",
                                                width:
                                                  item.dataType == "DATE"
                                                    ? "350"
                                                    : "240",
                                                trigger: "click",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                [
                                                  item.dataType == "DATE"
                                                    ? _c("el-date-picker", {
                                                        key: item.key,
                                                        attrs: {
                                                          "value-format":
                                                            "timestamp",
                                                          type: "daterange",
                                                          "range-separator":
                                                            "至",
                                                          "start-placeholder":
                                                            "开始日期",
                                                          "end-placeholder":
                                                            "结束日期",
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.changeDateSearch(
                                                              item
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            item.filterValue,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "filterValue",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.filterValue",
                                                        },
                                                      })
                                                    : _c("el-input", {
                                                        attrs: {
                                                          placeholder:
                                                            "搜索 按下Enter确认",
                                                        },
                                                        nativeOn: {
                                                          keyup: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              !$event.type.indexOf(
                                                                "key"
                                                              ) &&
                                                              _vm._k(
                                                                $event.keyCode,
                                                                "enter",
                                                                13,
                                                                $event.key,
                                                                "Enter"
                                                              )
                                                            ) {
                                                              return null
                                                            }
                                                            return _vm.enterTableSearch(
                                                              item
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            item.filterValue,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "filterValue",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.filterValue",
                                                        },
                                                      }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "ul",
                                                { staticClass: "popover-list" },
                                                [
                                                  _c(
                                                    "li",
                                                    [
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: {
                                                            label: "filledIn",
                                                          },
                                                          model: {
                                                            value:
                                                              item.filterType,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "filterType",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.filterType",
                                                          },
                                                        },
                                                        [_vm._v("已填写")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "li",
                                                    [
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: {
                                                            label:
                                                              "notFilledIn",
                                                          },
                                                          model: {
                                                            value:
                                                              item.filterType,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "filterType",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.filterType",
                                                          },
                                                        },
                                                        [_vm._v("未填写")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _c("i", {
                                                staticClass:
                                                  "tab-header-item-icon el-icon-caret-bottom",
                                                attrs: { slot: "reference" },
                                                slot: "reference",
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          })
                        }),
                      ],
                      2
                    ),
                    _c("el-pagination", {
                      staticClass: "sj-pagination",
                      attrs: {
                        "current-page": _vm.pageIndex,
                        "page-sizes": [10, 15, 20, 50],
                        "page-size": _vm.pageSize,
                        layout: "total, sizes, prev, pager, next, jumper",
                        total: _vm.total,
                      },
                      on: {
                        "size-change": _vm.handleSizeChange,
                        "current-change": _vm.handleCurrentChange,
                      },
                    }),
                  ]
                : [
                    _c("div", { staticClass: "no-form" }, [
                      _vm._v(_vm._s(_vm.$t("lang_please_select_a_form_first"))),
                    ]),
                  ],
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.formDialogVisible,
            width: "90%",
            top: "1vh",
            "append-to-body": "",
            "close-on-click-modal": false,
            "custom-class": "cus-data-viewer",
          },
          on: {
            "update:visible": function ($event) {
              _vm.formDialogVisible = $event
            },
          },
        },
        [
          _c("template", { slot: "title" }, [
            _c("div", { staticClass: "my-viewer-title-img" }),
            _c("div", { staticClass: "my-viewer-title" }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.formDataId
                      ? _vm.$t("lang_edit_form_data")
                      : _vm.$t("lang_new_document")
                  )
                ),
              ]),
            ]),
          ]),
          _vm.formDialogVisible
            ? _c("form-viewer", {
                ref: "formViewerRef",
                attrs: { use: "data" },
              })
            : _vm._e(),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.formDialogVisible = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("lang_shut_down")))]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleSave } },
                [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }