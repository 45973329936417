














































































































































































































import { Component, Prop, ProvideReactive, Vue, Watch } from 'vue-property-decorator';
import { deleteFormData, getFormData, getFormDataByWidget, saveFormData } from '@/api/form-design/FormApi';
import {
  originTableColumnsModel,
  conditionGroupsModel,
  ConditionGroupsInit,
  tableColumnsModel,
  TableColumnsInit,
  OrderConfigsInit,
  orderConfigsModel
} from './TableType';
import _ from 'lodash';
import { getFormConfig } from '@/api/form-design/FormApi';
import { buildFormWidgetMap, getComponentInfo } from '@/components/form-design/utils';
import { saveFormConfigsData } from '@/api/form-design/FormApi';
import DateUtil from '@/utils/DateUtil';

@Component({
  name: 'FormDataManager',
  components: { FormViewer: () => import('@/components/form-design/viewer/form-viewer.vue') }
})
export default class FormDataManager extends Vue {
  @Prop({
    default() {
      return {};
    }
  })
  form!: any;

  @Prop({
    default() {
      return '';
    }
  })
  tableTitle: string;

  pageIndex: number = 1; //当前页面
  pageSize: number = 10; // 当前页数
  total: number = 0; // 总条数
  searchForm: any = {};

  @ProvideReactive() formId: any = null;
  @ProvideReactive() formDataId: any = null;

  tableConfig: any = {
    columnKeys: [],
    htmlKeys: [],
    visibleKey: '', // 结果显示项
    valueKey: [] // 主键
  }; // 表单列配置

  // 原始表头数据
  originTableColumns: Array<originTableColumnsModel> = [];
  // 表单的列
  tableColumns: Array<tableColumnsModel> = [];
  conditionGroups: Array<conditionGroupsModel> = _.cloneDeep(ConditionGroupsInit);
  //排序过滤值
  orderConfigs: Array<orderConfigsModel> = _.cloneDeep(OrderConfigsInit);
  //表头搜索时的状态栏
  tableSearchStateList: any[] = [];
  //表头字段 用于过滤
  tableHeaderField: any[] = [];
  showAllSearchInput: boolean = false;
  allSearchQuery: string = '';
  // 获取组件的总数据
  formConfig: any = {};

  activeName: string = 'first';
  //列表选择的值
  multipleSelection: any[] = [];

  tableData: any = []; // 表单分页数据
  formDialogVisible: boolean = false;

  created() {
    this.getComponentsMap();
    this.getData();
  }

  // 点击搜索 显示搜索框并使input获取焦点
  clickSearchButton() {
    this.showAllSearchInput = true;
    this.$nextTick(() => {
      (this.$refs.allSearchInput as any).focus();
    });
  }
  handleAllSearch() {
    if (this.allSearchQuery) return;
    this.showAllSearchInput = false;
  }
  enterHandleAllSearch(e) {
    //enter后失去焦点 触发handleAllSearch函数
    if (e) {
      e.target.blur();
    }
  }

  // 获取组件数据 调用single接口
  getComponentsMap() {
    getFormConfig({ id: this.form.id }).then((res: any) => {
      if (res.code == '1' && res.data) {
        this.formConfig = res.data;
        let formWidgetMap = buildFormWidgetMap(this.formConfig.container.components);
        this.buildTableColumn(formWidgetMap);
        this.initfilterTableColumn(formWidgetMap);
      }
    });
  }

  handleCheckAllChange(val) {
    this.tableHeaderField.map(item => {
      item.display = val ? true : false;
    });
  }

  //获取表格数据源
  getData(filterSearch?) {
    if (this.form.code) {
      let params: any = {
        formCode: this.form.code,
        page: {
          pageIndex: this.pageIndex,
          pageSize: this.pageSize
        }
      };
      // 搜索 当conditionGroups有搜索项目时
      if (filterSearch == 'filterSearch') {
        if (this.conditionGroups[1].conditions.length && this.conditionGroups[0].conditions.length) {
          params.conditionGroups = this.conditionGroups;
        } else if (this.conditionGroups[1].conditions.length) {
          params.conditionGroups = [this.conditionGroups[1]];
        } else if (this.conditionGroups[0].conditions.length) {
          params.conditionGroups = [this.conditionGroups[0]];
        }
        // 构建排序 搜索字段
        let orderConfigs = this.bulidSortParams();
        if (orderConfigs) params.orderConfigs = orderConfigs;
      }

      return new Promise((resolve, reject) => {
        getFormData(params)
          .then((res: any) => {
            if (res.code == 1) {
              if (res.data && res.data.list && res.data.list.length) {
                if (!filterSearch) {
                  this.buildTableConfig(res.data.list);
                }
                this.buildTableData(res.data);
              } else {
                this.tableConfig = {
                  columnKeys: [],
                  htmlKeys: [],
                  visibleKey: '', // 结果显示项
                  valueKey: [] // 主键
                }; //
                this.tableData = [];
                this.pageIndex = 1;
                this.pageSize = 10;
                this.total = 0;
              }
              resolve('ok');
            } else {
              this.$message.error(res.message);
            }
          })
          .catch(err => {
            this.$message.error(err);
            reject(err);
          });
      });
      // 获取所有表单数据
    }
  }

  //字段管理 点击保存
  saveFieldManagementFn(): void {
    let originMap = {};
    this.originTableColumns.map(item => {
      originMap[item.key] = item;
    });
    let result = [];
    let columns = this.tableHeaderField;
    columns = columns.map(item => {
      if (item.display) {
        let formConfigitem = getComponentInfo(this.formConfig.container.components, item.key);
        formConfigitem.display = true;
        result.push(originMap[item.key]);
      } else {
        let formConfigitem = getComponentInfo(this.formConfig.container.components, item.key);
        formConfigitem.display = false;
      }
    });
    this.saveColumnConfig();
    this.tableColumns = result;
    (this.$refs.visiblepop as any).doClose();
  }

  saveColumnConfig() {
    saveFormConfigsData(this.formConfig).then((res: Record<string, any>) => {
      if (res.code == '1') {
        this.$message.success(res.message);
      } else {
        this.$message.error(res.message);
      }
    });
  }

  //初始化 表头字段管理
  initfilterTableColumn(tableData): void {
    let result = [];
    Object.keys(tableData).map(keys => {
      if (!['formId', 'formDataId'].includes(tableData[keys].key)) {
        let item = tableData[keys];
        let newCol: {
          display: string;
          title: string;
          key: string;
        } = {
          display: '',
          title: '',
          key: ''
        };
        newCol.display = item.display;
        newCol.title = item.displayName;
        newCol.key = item.compCode;
        result.push(newCol);
      }
    });
    this.tableHeaderField = result;
  }

  /**
   * 构建表格表头
   * @param {void} null
   * @returns void
   */
  buildTableColumn(tableData) {
    let columns = [];
    if (tableData) {
      Object.keys(tableData).map((keys: any, index) => {
        let item = tableData[keys];
        let col: tableColumnsModel = _.cloneDeep(TableColumnsInit);
        col.index = String(index);
        col.filterValue = '';
        col.html = item.properties.html;
        col.key = item.compCode;
        col.title = item.displayName;
        col.dataType = item.dataType;
        col.display = item.display;
        col.sortFilter = 'DEFAULT';
        columns.push(col);
      });
      this.originTableColumns = _.cloneDeep(columns);
      this.tableColumns = columns.filter(item => item.display);
    }
  }

  /**
   * 构建表格配置
   * @param {number} param
   * @returns {number}
   */
  buildTableConfig(tableData) {
    if (tableData && tableData.length) {
      let columns = tableData[0].columns;
      columns.forEach(item => {
        if (item.display) {
          this.tableConfig.columnKeys.push(item.key);
        }
        if (item.html) {
          this.tableConfig.htmlKeys.push(item.key);
        }
        if (item.visible) {
          this.tableConfig.visibleKey = item.key;
        }
        if (item.val) {
          this.tableConfig.valueKey.push(item.key);
        }
      });
    }
  }

  /**
   * 构建表格数据
   * @param {number} param
   * @returns {number}
   */
  buildTableData(pageInfo) {
    let result: any[] = [];
    let tableData = pageInfo.list;
    tableData.forEach(row => {
      let item = {};
      row.columns.forEach(column => {
        item[column.key] = column.value;
      });
      result.push(item);
    });
    this.tableData = result;
    this.pageIndex = pageInfo.pageNum;
    this.pageSize = pageInfo.pageSize;
    this.total = pageInfo.total;
    return result;
  }

  //清空搜索
  resetSearch() {
    this.searchForm = {};
  }

  // 当前页码修改时
  handleCurrentChange(pageIndex) {
    this.pageIndex = pageIndex;
    this.getData('filterSearch');
  }
  // 当前页数修改时
  handleSizeChange(pageSize) {
    this.pageSize = pageSize;
    this.getData('filterSearch');
  }

  /**
   * 新增数据
   */
  handleAdd() {
    this.formDataId = null;
    this.formId = this.form.id;
    this.formDialogVisible = true;
  }

  /**
   * description
   */
  handleEidt() {
    let row = this.multipleSelection[0];
    this.formId = this.form.id;
    this.formDataId = row.formDataId;
    this.formDialogVisible = true;
  }

  /**
   * description  提交
   * @param {}
   * @returns {}
   */
  handleSave() {
    (this.$refs.formViewerRef as any).getFormDataForSave().then(result => {
      saveFormData(result).then((res: Record<string, any>) => {
        if (res.code == '1') {
          this.$message.success(res.message);
          this.formDialogVisible = false;
          this.getData();
        }
      });
    });
  }

  /**
   * 删除表单数据
   */
  handleDelete(row) {
    this.$confirm(this.$t('lang_whether_or_not_to_delete') as string, this.$t('lang_tips') as string, {
      confirmButtonText: this.$t('lang_determine_') as string,
      cancelButtonText: this.$t('lang_cancel_') as string,
      type: 'warning'
    })
      .then(() => {
        let params = {};
        this.tableConfig.valueKey.forEach(key => {
          params[key] = row[key];
        });
        deleteFormData(params).then((res: Record<string, any>) => {
          if (res.code == '1') {
            this.$message.success(res.message);
            this.getData();
          }
        });
      })
      .catch(() => {});
  }

  handleSelectionChange(val): void {
    this.multipleSelection = val;
  }

  changeDateSearch(current) {
    this.enterTableSearch(current);
  }

  //点击表头 搜索按下Enter
  // v当前输入的值 i 索引
  enterTableSearch(current?) {
    // 初始化
    this.conditionGroups = _.cloneDeep(ConditionGroupsInit);
    let sortFlg = false;
    // 处理数据
    this.tableColumns.map((item, index) => {
      let filterValue = _.cloneDeep(item.filterValue);
      let dataType = this.getCompare(item.dataType);
      if (item.sortFilter != 'DEFAULT') sortFlg = true;
      if (filterValue && dataType != 'DATE') {
        let conditionsItem = {
          compare: dataType,
          filter: {
            type: item.dataType
          },
          first: index === 0 ? true : false,
          key: item.key,
          source: 'INPUT',
          logical: 'AND',
          tableName: 'formModelData',
          value: [{ type: 'FIXED_VALUE', value: filterValue }]
        };
        this.conditionGroups[0].conditions.push(conditionsItem);
      } else if (filterValue && filterValue.length) {
        // 等于DATE时
        let itemTemplate = {
          compare: '',
          first: index === 0 ? true : false,
          key: item.key,
          filter: {
            type: item.dataType
          },
          source: 'INPUT',
          logical: 'AND',
          tableName: 'formModelData',
          value: [{ type: 'FIXED_VALUE', value: '' }]
        };
        let itme2 = JSON.parse(JSON.stringify(itemTemplate));
        itemTemplate.compare = 'GE';
        itemTemplate.value[0].value = filterValue[0];
        itme2.compare = 'LE';
        itme2.value[0].value = filterValue[1];
        this.conditionGroups[1].conditions.push(itemTemplate);
        this.conditionGroups[1].conditions.push(itme2);
      }
    });
    // 搜索
    if (this.conditionGroups[0].conditions.length || this.conditionGroups[1].conditions.length || sortFlg) {
      this.getData('filterSearch');
      let key = '';
      if (!current) return;
      this.tableSearchStateList.map((item, index) => {
        if (item.key == current.key + '-SEARCH') {
          key = item.key;
          // 时间
          let label = _.cloneDeep(current.filterValue);
          if (Array.isArray(label)) {
            let str = '';
            let data1 = new Date(label[0]);
            label[0] = DateUtil.format(data1, 'yyyy-MM-dd');
            let data2 = new Date(label[1]);
            label[1] = DateUtil.format(data2, 'yyyy-MM-dd');
            label = label.join('-');
          }
          this.toUpdateTableSearchStateList(label, index);
          return;
        }
      });
      if (!key) this.addTableSearchStateList(current, 'SEARCH');
    } else {
      this.getData();
    }
  }
  // 移除搜索条件
  deleteSearchList(current, index) {
    this.tableColumns.map(item => {
      if (item.key + '-SEARCH' == current.key) {
        item.filterValue = '';
        return;
      } else if (item.key + '-SORT' == current.key) {
        item.sortFilter = 'DEFAULT';
        return;
      }
    });
    // 移除
    this.tableSearchStateList.splice(index, 1);
    (this.$refs.visiblepop as any).doClose();
    this.enterTableSearch();
  }

  // 增加
  addTableSearchStateList(current, flg?: string) {
    let label = _.cloneDeep(current.filterValue);
    if (Array.isArray(label)) {
      let data1 = new Date(label[0]);
      label[0] = DateUtil.format(data1, 'yyyy-MM-dd');
      let data2 = new Date(label[1]);
      label[1] = DateUtil.format(data2, 'yyyy-MM-dd');
      label = label.join('-');
    }
    let type = flg ? flg : 'SEARCH';
    this.tableSearchStateList.push({
      key: current.key + `-${type}`,
      title: current.title,
      label: label
    });
  }
  // 更新
  toUpdateTableSearchStateList(updateVal, index) {
    this.tableSearchStateList[index].label = updateVal;
  }
  // 获取比较符号
  getCompare(dataType): string {
    // group1 -- string ：包含; collect、tree：包含; boolean、number：等于
    // group2 -- date： 单独条件组，两个条件
    // 比较符枚举
    let compare = {
      EQ: 'EQ', //等于
      INC: 'INC', //包含
      GE: 'GE', //大于
      LE: 'LE' //小于
    };
    if (dataType == 'BOOLEAN' || dataType == 'NUMBER') return compare.EQ;
    if (dataType == 'TREE' || dataType == 'COLLECT') return compare.INC;
    if (dataType == 'STRING') return compare.INC;
    if (dataType == 'DATE') return dataType;
  }

  //排序
  handlerSort(item) {
    if (item.sortFilter == 'DEFAULT') {
      this.handlerSortAdd(item);
    } else {
      this.handlerSortUpdate(item);
    }
  }
  handlerSortAdd(item) {
    let addFlg = true;
    this.tableSearchStateList.map(item => {
      if (item.key == item.key + '-SORT') {
        addFlg = false;
        return;
      }
    });
    if (addFlg) {
      item.sortFilter = 'UP';
      this.addTableSearchStateList(
        {
          key: item.key,
          title: item.title,
          filterValue: '升序'
        },
        'SORT'
      );
      this.enterTableSearch();
    } else {
      console.log('错误添加');
      // this.handlerSortUpdate(item);
    }
  }
  handlerSortUpdate(item) {
    if (item.sortFilter == 'UP') {
      item.sortFilter = 'DOWN';
      this.tableSearchStateList.map((i, index) => {
        if (i.key == item.key + '-SORT') {
          this.toUpdateTableSearchStateList('降序', index);
          return;
        }
      });
      this.enterTableSearch();
      return;
    } else if (item.sortFilter == 'DOWN') {
      item.sortFilter = 'UP';
      this.tableSearchStateList.map((i, index) => {
        if (i.key == item.key + '-SORT') {
          this.toUpdateTableSearchStateList('升序', index);
          return;
        }
      });
      this.enterTableSearch();
      return;
    }
  }

  bulidSortParams(): Array<orderConfigsModel> {
    let rules = _.cloneDeep(OrderConfigsInit);
    this.tableColumns.map(item => {
      if (item.sortFilter == 'UP') {
        rules[0].orderByColumns.push({ key: item.key });
      }
      if (item.sortFilter == 'DOWN') {
        rules[1].orderByColumns.push({ key: item.key });
      }
    });
    if (rules[0].orderByColumns.length && rules[1].orderByColumns.length) {
      return rules;
    } else if (rules[0].orderByColumns.length) {
      return [rules[0]];
    } else if (rules[1].orderByColumns.length) {
      return [rules[1]];
    }
    return null;
  }

  @Watch('form')
  formCodeWatcher(val) {
    this.getData();
  }
}
